import './i18n';
import './index.scss';
import {
  Amplify,
  Auth,
} from 'aws-amplify';
import {
  DEFAULT_LC_BUILDER,
  DEFAULT_LOCALE,
} from './constants';
import AppInitWrapper from './components/AppInitWrapper';
import AuthenticateUser from './components/Auth/AuthenticateUser';
import { ErrorBoundary } from 'react-error-boundary';
import FallbackError from './components/common/FallbackError';
import { MbmProvider } from '@amzn/react-arb-tools';
import React from 'react';
import { Spinner } from '@amzn/awsui-components-react';
import { createRoot } from 'react-dom/client';
import { debug } from './utils';

const arbManifest = require('../build/i18n/arbManifest');

// @ts-ignore because this is a replacement var
if ('beta'.toLowerCase() !== 'prod') {
  Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

debug('AuthenticateUser() Stage is ' + 'beta');
debug('AuthenticateUser() AWS_REGION is ' + 'us-east-1');
debug('AuthenticateUser() AWS_ACCOUNT is ' + '381492069968');
debug('AuthenticateUser() GUEST_ROLE_ARN is ' + 'arn:aws:iam::381492069968:role/GSO-SPOT-Website-Unauthenticated-Role-beta');
debug('AuthenticateUser() IDENTITY_POOL_DOMAIN is ' + 'gso-spot-beta.auth.us-east-1.amazoncognito.com');
debug('AuthenticateUser() IDENTITY_POOL_ID is ' + 'us-east-1:4a1ee610-f2d4-4e7d-bcb4-7473344228c3');
debug('AuthenticateUser() USER_POOL_ID is ' + 'us-east-1_HQjqIpIS0');

const callbackUrl = window.location.origin;
const config = {
  API: {
    graphql_endpoint: 'https://api.beta.spot.gso.amazon.dev/graphql',
    graphql_headers: async () => ({
      Authorization: (await Auth.currentAuthenticatedUser())
        .getSignInUserSession()
        .getIdToken()
        .getJwtToken(),
    }),
  },
  // AppSync
  aws_appsync_graphqlEndpoint: 'https://api.beta.spot.gso.amazon.dev/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  // Cognito
  aws_cognito_identity_pool_id: 'us-east-1:4a1ee610-f2d4-4e7d-bcb4-7473344228c3',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_HQjqIpIS0',
  aws_user_pools_web_client_id: 'qbj62tl9q4a2708rr375774ak',
  oauth: {
    domain: 'gso-spot-beta.auth.us-east-1.amazoncognito.com',
    scope: ['openid'],
    redirectSignIn: callbackUrl,
    redirectSignOut: callbackUrl,
    responseType: 'code',
  },
};
Amplify.configure(config);

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: DEFAULT_LC_BUILDER.withLocale(DEFAULT_LOCALE).withDefaultLocale(DEFAULT_LOCALE).build(),
  urlPrefix: '/i18n',
};
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AppInitWrapper>
      <React.Suspense fallback={<Spinner size='large' />}>
        <ErrorBoundary FallbackComponent={FallbackError}>
          <MbmProvider {...mbmOptions}>
            <AuthenticateUser />
          </MbmProvider>
        </ErrorBoundary>
      </React.Suspense>
    </AppInitWrapper>
  </React.StrictMode>
);
