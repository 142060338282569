import {
  Device,
  DeviceSettings,
  SiteHardwareConfiguration,
} from 'src/API';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { debug } from 'src/utils';
import { useImmerReducer } from 'use-immer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: {
      connect: () => {
        init: (state: any) => void;
        send: (action: string, state: any) => void;
      };
    };
  }
}

const stage = 'beta';
// @ts-ignore
const isDevelopment = stage === 'test';
const devTools = isDevelopment ? window.__REDUX_DEVTOOLS_EXTENSION__?.connect() : undefined;

export enum OpenReviewActionType {
  close = 'close',
  closeAll = 'closeAll',
  open = 'open',
  save = 'save',
  saveDevice = 'saveDevice',
  saving = 'saving',
  setCurrentDeviceId = 'setCurrentDeviceId',
  setCurrentReview = 'setCurrentReview',
  setDevices = 'setDevices',
  undo = 'undo',
  undoDevice = 'undoDevice',
  update = 'update',
  updateDevice = 'updateDevice',
}

export interface IOpenReview {
  changed: boolean;
  id: string;
  savedReview: SiteHardwareConfiguration;
  saving: boolean;
  temporaryReview: SiteHardwareConfiguration;
}

export interface IOpenReviewsContext {
  currentDeviceId: string | null,
  currentReview: IOpenReview | null,
  dispatch: Function;
  reviews: IOpenReview[];
}

const initialOpenReviewsContext: IOpenReviewsContext = {
  currentDeviceId: null,
  currentReview: null as IOpenReview | null,
  dispatch: openReviewsReducer,
  reviews: [],
};

export const OpenReviewsContext = createContext<IOpenReviewsContext>(initialOpenReviewsContext);

export function useOpenReviewsContext() {
  return useContext(OpenReviewsContext);
}

type OpenReviewsAction =
  | { type: OpenReviewActionType.close; payload: { } }
  | { type: OpenReviewActionType.closeAll; payload: { } }
  | { type: OpenReviewActionType.open; payload: { review: SiteHardwareConfiguration } }
  | { type: OpenReviewActionType.save; payload: { review: SiteHardwareConfiguration } }
  | { type: OpenReviewActionType.saveDevice; payload: { } }
  | { type: OpenReviewActionType.saving; payload: { } }
  | { type: OpenReviewActionType.setCurrentDeviceId; payload: { deviceId: string } }
  | { type: OpenReviewActionType.setCurrentReview; payload: { review: SiteHardwareConfiguration } }
  | { type: OpenReviewActionType.setDevices; payload: { devices: Device[] } }
  | { type: OpenReviewActionType.undo; payload: { } }
  | { type: OpenReviewActionType.undoDevice; payload: { deviceId?: string } }
  | { type: OpenReviewActionType.update; payload: { review: SiteHardwareConfiguration } }
  | { type: OpenReviewActionType.updateDevice; payload: { device: Device } };

function openReviewsReducer(draft: IOpenReviewsContext, action: OpenReviewsAction) {
  debug(`openReviewsReducer() draft is ${JSON.stringify(draft)} action is ${JSON.stringify(action)}`);
  debug(`openReviewsReducer() action is ${JSON.stringify(action)}`);
  debug(`openReviewsReducer() action.type is ${action.type}`);

  const setCurrentReview = (review: SiteHardwareConfiguration) => {
    if (draft.currentReview?.id && draft.currentReview?.id !== (review?.id || '')) {
      // add current review to reviews
      const currentReviewIndex = draft.reviews.findIndex(d => d.id === draft.currentReview!.id);
      if (currentReviewIndex >= 0) {
        // remove if current review found in reviews
        draft.reviews.splice(currentReviewIndex, 1);
      }
      draft.reviews.push(draft.currentReview);
    }
    if (review === null) {
      draft.currentReview = null;
      return;
    }
    const newCurrentReview: IOpenReview = 
      {
        changed: false,
        id: review.id,
        savedReview: review,
        saving: false,
        temporaryReview: review,
      };
    draft.currentReview = newCurrentReview;
    const newCurrentReviewIndex = draft.reviews.findIndex(d => d.id === newCurrentReview.id);
    if (newCurrentReviewIndex >= 0) {
      // remove new current review from reviews
      draft.reviews.splice(newCurrentReviewIndex, 1);
    }
  };

  let
    currentDevice: Device | undefined,
    foundDeviceInCurrentReview: boolean,
    savedDevice: Device | undefined;

  switch (action?.type) {

    case OpenReviewActionType.close:
      draft.currentReview = null;
      draft.currentDeviceId = null;
      break;

    case OpenReviewActionType.closeAll:
      draft.currentReview = null;
      draft.currentDeviceId = null;
      draft.reviews = [];
      break;

    case OpenReviewActionType.open:
      return setCurrentReview(action.payload.review);

    case OpenReviewActionType.save:
      if (!draft.currentReview) return;
      if (!action.payload.review) return;
      draft.currentReview.savedReview = action.payload.review;
      draft.currentReview.temporaryReview = action.payload.review;
      draft.currentReview.changed = false;
      draft.currentReview.saving = false;
      break;

    case OpenReviewActionType.saveDevice:
      if (!draft.currentReview) return;
      if (!draft.currentDeviceId) return;
      draft.currentReview.savedReview.devices = draft.currentReview?.temporaryReview.devices?.filter(d => d.id !== draft.currentDeviceId) || [];
      currentDevice = draft.currentReview?.temporaryReview.devices?.find(d => d.id === draft.currentDeviceId);
      if (!currentDevice) return;
      draft.currentReview.savedReview.devices.push(currentDevice);
      break;

    case OpenReviewActionType.saving:
      if (!draft.currentReview) return draft;
      draft.currentReview.saving = true;
      break;

    case OpenReviewActionType.setCurrentReview:
      return setCurrentReview(action.payload.review);

    case OpenReviewActionType.setCurrentDeviceId:
      if (!draft.currentReview) return;
      if (draft.currentDeviceId === action.payload.deviceId) return;
      if (action.payload.deviceId === null) {
        draft.currentDeviceId = null;
        return;
      }
      foundDeviceInCurrentReview = draft.currentReview?.temporaryReview?.devices?.find(d => d.id === action.payload.deviceId) !== undefined;
      if (!foundDeviceInCurrentReview) return;
      draft.currentDeviceId = action.payload.deviceId;
      break;

    case OpenReviewActionType.setDevices:
      if (!draft.currentReview) return;
      if (!draft.currentReview.temporaryReview) return;
      if (!action.payload.devices) return;
      draft.currentReview.temporaryReview.devices = action.payload.devices;
      draft.currentReview.changed = true;
      break;

    case OpenReviewActionType.undo:
      if (!draft.currentReview) return draft;
      draft.currentReview.temporaryReview = draft.currentReview.savedReview;
      draft.currentReview.changed = false;
      draft.currentDeviceId = null;
      break;


    case OpenReviewActionType.undoDevice:
      if (!draft.currentReview) return;
      if (!draft.currentDeviceId && !action.payload.deviceId) return;
      draft.currentReview.temporaryReview.devices = draft.currentReview?.temporaryReview.devices
        ?.filter(d => {
          if (action.payload.deviceId !== null && action.payload.deviceId !== undefined) return d.id !== action.payload.deviceId;
          return d.id !== draft.currentDeviceId;
        }) || [];
      savedDevice = draft.currentReview?.savedReview.devices
        ?.find(d => {
          if (action.payload.deviceId !== null && action.payload.deviceId !== undefined) return d.id === action.payload.deviceId;
          return d.id === draft.currentDeviceId
      });
      if (!savedDevice) {
        const tempDevice = draft.currentReview.temporaryReview.devices?.find(d => {
          if (action.payload.deviceId !== null && action.payload.deviceId !== undefined) return d.id === action.payload.deviceId;
          return d.id === draft.currentDeviceId;
        }) || undefined;
        if (!tempDevice) return;
        savedDevice = {
          ...tempDevice,
          settings: tempDevice.settings?.map(settings => {
            if (!settings) return settings;
            const newSettings: DeviceSettings = {
              ...settings,
              value: '',
            };
            return newSettings;
          }),
        };
      }
      if (!savedDevice) return;
      if (!draft.currentReview.temporaryReview.devices) draft.currentReview.temporaryReview.devices = [];
      draft.currentReview.temporaryReview.devices.push(savedDevice);
      draft.currentReview.changed = true;
      break;

    case OpenReviewActionType.update:
      if (!draft.currentReview) return draft;
      draft.currentReview.temporaryReview = action.payload.review;
      draft.currentReview.changed = true;
      break;

    case OpenReviewActionType.updateDevice:
      if (!draft.currentReview) return;
      if (!action.payload.device?.id) return;
      draft.currentReview.temporaryReview.devices = draft.currentReview?.temporaryReview.devices?.filter(d => d.id !== action.payload.device.id) || [];
      draft.currentReview.temporaryReview.devices.push(action.payload.device);
      draft.currentReview.changed = true;
      break;

    default:
      return;
  }
}

export function OpenReviewsProvider({ children }: { children: ReactNode }) {

  const [openReviewsContext, openReviewsContextDispatch] = useImmerReducer(openReviewsReducer, initialOpenReviewsContext);

  useEffect(() => {
    if (devTools) {
      devTools.init(openReviewsContext);
    }
  }, []);

  const openReviewsContextDispatchWithDevTools = useCallback((action: OpenReviewsAction) => {
    openReviewsContextDispatch(action);
    if (devTools) {
      devTools.send(action.type, openReviewsContext);
    }
  }, [openReviewsContext]);

  return(
    <OpenReviewsContext.Provider
      value={
        {
          currentDeviceId: openReviewsContext.currentDeviceId,
          currentReview: openReviewsContext.currentReview,
          dispatch: isDevelopment ? openReviewsContextDispatchWithDevTools : openReviewsContextDispatch,
          reviews: openReviewsContext.reviews,
        }
      }
    >
      {children}
    </OpenReviewsContext.Provider>
  );
}
