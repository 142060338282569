
import * as uuid from 'uuid';
import {
  Device,
  DeviceSettings,
  HardwareType,
  SiteHardwareConfiguration,
} from 'src/API';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { debug } from 'src/utils';
import { produce } from 'immer';
import { useImmerReducer } from 'use-immer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: {
      connect: () => {
        init: (state: any) => void;
        send: (action: string, state: any) => void;
      };
    };
  }
}

const stage = 'beta';
// @ts-ignore
const isDevelopment = stage === 'test';
const devTools = isDevelopment ? window.__REDUX_DEVTOOLS_EXTENSION__?.connect() : undefined;

export enum OpenDocumentActionType {
  addDevice = 'addDevice',
  close = 'close',
  closeAll = 'closeAll',
  open = 'open',
  removeDevice = 'removeDevice',
  save = 'save',
  saveDevice = 'saveDevice',
  saving = 'saving',
  setCurrentDeviceId = 'setCurrentDeviceId',
  setCurrentDocument = 'setCurrentDocument',
  setDevices = 'setDevices',
  setParentReadersCount = 'setParentReadersCount',
  undo = 'undo',
  undoDevice = 'undoDevice',
  update = 'update',
  updateDevice = 'updateDevice',
  updateDeviceForRulesEvaluation = 'updateDeviceForRulesEvaluation',
}

export interface IOpenDocument {
  changed: boolean;
  id: string;
  savedDocument: SiteHardwareConfiguration;
  saving: boolean;
  temporaryDocument: SiteHardwareConfiguration;
}

export interface IOpenDocumentsContext {
  currentDeviceId: string | null,
  currentDocument: IOpenDocument | null,
  dispatch: Function;
  documents: IOpenDocument[];
}

const initialOpenDocumentsContext: IOpenDocumentsContext = {
  currentDeviceId: null,
  currentDocument: null as IOpenDocument | null,
  dispatch: openDocumentsReducer,
  documents: [],
};

export const OpenDocumentsContext = createContext<IOpenDocumentsContext>(initialOpenDocumentsContext);

export function useOpenDocumentsContext() {
  return useContext(OpenDocumentsContext);
}

type OpenDocumentsAction =
  | { type: OpenDocumentActionType.addDevice; payload: { device: Device } }
  | { type: OpenDocumentActionType.close; payload: { } }
  | { type: OpenDocumentActionType.closeAll; payload: { } }
  | { type: OpenDocumentActionType.open; payload: { document: SiteHardwareConfiguration } }
  | { type: OpenDocumentActionType.save; payload: { document: SiteHardwareConfiguration } }
  | { type: OpenDocumentActionType.saveDevice; payload: { } }
  | { type: OpenDocumentActionType.saving; payload: { } }
  | { type: OpenDocumentActionType.removeDevice; payload: { deviceId?: string } }
  | { type: OpenDocumentActionType.setCurrentDeviceId; payload: { deviceId: string } }
  | { type: OpenDocumentActionType.setCurrentDocument; payload: { document: SiteHardwareConfiguration } }
  | { type: OpenDocumentActionType.setDevices; payload: { devices: Device[] } }
  | { type: OpenDocumentActionType.setParentReadersCount; payload: { deviceId: string } }
  | { type: OpenDocumentActionType.undo; payload: { } }
  | { type: OpenDocumentActionType.undoDevice; payload: { deviceId?: string } }
  | { type: OpenDocumentActionType.update; payload: { document: SiteHardwareConfiguration } }
  | { type: OpenDocumentActionType.updateDevice; payload: { device: Device } }
  | { type: OpenDocumentActionType.updateDeviceForRulesEvaluation; payload: { device: Device } };

function openDocumentsReducer(draft: IOpenDocumentsContext, action: OpenDocumentsAction) {
  debug(`openDocumentsReducer() draft is ${JSON.stringify(draft)} action is ${JSON.stringify(action)}`);
  debug(`openDocumentsReducer() action is ${JSON.stringify(action)}`);
  debug(`openDocumentsReducer() action.type is ${action.type}`);

  const setCurrentDocument = (document: SiteHardwareConfiguration) => {
    if (draft.currentDocument?.id && draft.currentDocument?.id !== (document?.id || '')) {
      // add current document to documents
      const currentDocumentIndex = draft.documents.findIndex(d => d.id === draft.currentDocument!.id);
      if (currentDocumentIndex >= 0) {
        // remove if current document found in documents
        draft.documents.splice(currentDocumentIndex, 1);
      }
      draft.documents.push(draft.currentDocument);
    }
    if (document === null) {
      draft.currentDocument = null;
      return;
    }
    const newCurrentDocument: IOpenDocument = 
      {
        changed: false,
        id: document.id,
        savedDocument: document,
        saving: false,
        temporaryDocument: document,
      };
    draft.currentDocument = newCurrentDocument;
    const newCurrentDocumentIndex = draft.documents.findIndex(d => d.id === newCurrentDocument.id);
    if (newCurrentDocumentIndex >= 0) {
      // remove new current document from documents
      draft.documents.splice(newCurrentDocumentIndex, 1);
    }
  };

  const findRootDevice = (deviceId: string): string | null => {
    const device = draft.currentDocument.temporaryDocument.devices.find(d => d.id === deviceId);
    if (!device.parentDeviceId) return device.id;
    return findRootDevice(device.parentDeviceId);
  }

  const setParentReadersCount = (parentDeviceId: string): number => {
    debug(`setParentReadersCount() parentDeviceId is ${parentDeviceId}`);
    const parentDevice = draft.currentDocument?.temporaryDocument.devices?.find(d => d.id === parentDeviceId);
    if (!parentDevice) return;
    debug(`setParentReadersCount() parentDevice is ${JSON.stringify(parentDevice)}`);
    let parentReadersCount = 0;
    const childDevices = draft.currentDocument.temporaryDocument.devices.filter(d => d.parentDeviceId === parentDevice.id);
    debug(`setParentReadersCount() childDevices is ${JSON.stringify(childDevices)}`);
    debug(`setParentReadersCount() childDevices.length is ${childDevices.length}`);
    if (childDevices.length === 0) return 0;
    parentReadersCount = childDevices.filter(d => d.hardwareType === HardwareType.Reader).length;
    for (const childDevice of childDevices) {
      parentReadersCount += setParentReadersCount(childDevice.id);
    }
    debug(`setParentReadersCount() parentReadersCount is ${parentReadersCount}`);
    const newParentDeviceSettings = parentDevice
      .settings.filter(s => s.key !== 'ReadersCount')
      .concat({
        key: 'ReadersCount',
        value: parentReadersCount.toString(),
        uiAttributes: parentDevice.settings.find(s => s.key === 'ReadersCount')?.uiAttributes,
      });
    debug(`setParentReadersCount() newParentDeviceSettings is ${JSON.stringify(newParentDeviceSettings)}`);
    const newDevices = produce(draft.currentDocument.temporaryDocument.devices.filter(d => d.id !== parentDevice.id), draft => draft);
    const newParentDevice = produce(draft.currentDocument.temporaryDocument.devices.find(d => d.id === parentDevice.id), draft => {
      draft.settings = newParentDeviceSettings;
    });
    draft.currentDocument.temporaryDocument.devices = [...newDevices, newParentDevice];
    debug(`setParentReadersCount() draft.currentDocument.temporaryDocument.devices is ${JSON.stringify(draft.currentDocument.temporaryDocument.devices)}`);
    return parentReadersCount;
  }

  const removeDevice = (deviceId: string, devices: Device[]): Device[] => {
    const device = draft.currentDocument?.temporaryDocument.devices?.find(d => d.id === deviceId);
    if (!device) return devices;
    const childDevices = draft.currentDocument?.temporaryDocument.devices?.filter(d => d.parentDeviceId === deviceId);
    if (childDevices && childDevices.length > 0) {
      childDevices.forEach(childDevice => {
        removeDevice(childDevice.id, devices);
      });
    }
    const deviceIndex = devices.findIndex(d => d.id === device.id);
    if (deviceIndex >= 0) {
      devices.splice(deviceIndex, 1);
    } 
    return devices;
  }

  const countType = (draft: SiteHardwareConfiguration, hardwareType: HardwareType): number => {
    let count = 0;
    for (const device of draft.devices) {
      if (device.hardwareType === hardwareType) {
        count++;
      }
    }
    return count;
  }

  const handleDeviceSegments = (draft: SiteHardwareConfiguration, device: Device): Device => {
    debug(`handleDeviceSegments() device is ${JSON.stringify(device)}`);
    const newDevice = {...device};
    const segmentKeyRegex = new RegExp(/\{[A-Za-z0-9_ :]*\}*/, 'g');
    try {
      for (const deviceSettings of device.settings) {
        const segments = [...deviceSettings?.segments ?? []];
        let newValue = '';
        for (const segment of segments.sort((a, b) => a.sequence < b.sequence ? -1 : 1)) {
          const keys = segment.value.match(segmentKeyRegex) ?? [];
          let newSegmentValue: string;
          if (keys.length === 0) {
            newSegmentValue = segment.value;
          }
          for (const key of keys) {
            switch (key) {
              case '{nextCount}':
                newSegmentValue = newSegmentValue
                  ? newSegmentValue.replaceAll(key, (countType(draft, device.hardwareType) + 1).toString())
                  : segment.value.replaceAll(key, (countType(draft, device.hardwareType) + 1).toString());
                break;

              case '{siteCode}':
                newSegmentValue = newSegmentValue
                  ? newSegmentValue.replaceAll(key, (draft.siteCode))
                  : segment.value.replaceAll(key, (draft.siteCode));
                break;
          
              default:
                if (/\{padded[0-9]*NextCount\}/.test(key)) {
                  const paddedCount = parseInt(key.replace('{padded', '').replace('NextCount}', ''));
                  newSegmentValue = newSegmentValue
                    ? newSegmentValue.replaceAll(key, (countType(draft, device.hardwareType) + 1).toString().padStart(paddedCount, '0'))
                    : segment.value.replaceAll(key, (countType(draft, device.hardwareType) + 1).toString().padStart(paddedCount, '0'));
                }
                if (/:/.test(key)) {
                  const identifiers = key.split(':').map(k => k);
                  let matchingParentDevice: Device;
                  let parentDeviceId = device.parentDeviceId;
                  while (parentDeviceId && !matchingParentDevice) {
                    const parentDevice = draft.devices.find(d => d.id === parentDeviceId);
                    if (parentDevice?.hardwareType === identifiers[0].replace(/\{|\}/g,'')) matchingParentDevice = parentDevice;
                    parentDeviceId = parentDevice?.parentDeviceId;
                    if (!parentDevice) break;
                  }
                  const attributeValue = matchingParentDevice?.settings?.find(s => s.key === identifiers[1].replace(/\{|\}/g,'')).value;
                  if (attributeValue) newSegmentValue = newSegmentValue
                    ? newSegmentValue.replaceAll(key, attributeValue.toString())
                    : segment.value.replaceAll(key, attributeValue.toString());
                }
                if (!/:/.test(key)) {
                  newSegmentValue = newSegmentValue
                    ? newSegmentValue.replaceAll(key, '')
                    : segment.value.replaceAll(key, '');
                }
            }
          }
          newValue = newValue + (newSegmentValue ?? segment.value) + deviceSettings.segmentDelimiter;
        }
        if (newValue && deviceSettings.segmentDelimiter) {
          // remove trailing delimiter
          newValue = newValue.slice(0, deviceSettings.segmentDelimiter.length * -1);
        }
        const newDeviceSettings = {...newDevice.settings.find(s => s.key === deviceSettings.key)};
        newDeviceSettings.value = newValue;
        newDevice.settings = newDevice.settings.filter(s => s.key !== deviceSettings.key);
        newDevice.settings.push(newDeviceSettings);
      }
    } catch(error) {
      console.error(error);
    }
    return newDevice;
  }

  let
    currentDevice: Device | undefined,
    foundDeviceInCurrentDocument: boolean,
    rootParentDeviceId: string | undefined,
    savedDevice: Device | undefined;

  switch (action?.type) {

    case OpenDocumentActionType.addDevice:
      if (!draft.currentDocument) return;
      if (!action.payload.device) return;
      if (action.payload.device.parentDeviceId) {
        const parentDevice = draft.currentDocument?.temporaryDocument.devices?.find(d => d.id === action.payload.device.parentDeviceId);
        if (!parentDevice) throw new Error('parent device not found');
      }
      if (draft.currentDocument?.temporaryDocument.devices?.find(d => d.id === action.payload.device.id)) {
        return;
      }
      if (!draft.currentDocument?.temporaryDocument.devices) draft.currentDocument.temporaryDocument.devices = [];
      draft.currentDocument.temporaryDocument.devices.push(
        handleDeviceSegments(
          draft.currentDocument.temporaryDocument,
          action.payload.device));
      draft.currentDocument.changed = true;
      draft.currentDeviceId = action.payload.device.id;
      rootParentDeviceId = findRootDevice(action.payload.device.id);
      if (rootParentDeviceId !== action.payload.device.id) setParentReadersCount(rootParentDeviceId);
      if (action.payload.device.slots) {
        debug(`OpenDocumentsContext() addDevice() newDevice.slots is ${JSON.stringify(action.payload.device.slots)}`);
        for (let i = 0; i < action.payload.device.slots?.length; i++) {
          const slot = action.payload.device.slots?.[i];
          if (!slot) continue;
          const slotId = uuid.v4();
          draft.currentDocument.temporaryDocument.devices.push({
            settings: [],
            hardwareDeviceName: slot.name,
            hardwareType: HardwareType.Port,
            id: slotId,
            name: slot.name,
            parentDeviceId: action.payload.device.id,
          });
          const numAddresses = slot.settings?.find(ss => ss?.key === 'numAddresses')?.value || '0';
          debug(`OpenDocumentsContext() addDevice() numAddresses is ${numAddresses}`);
          for (let j = 0; j < parseInt(numAddresses); j++) {
            draft.currentDocument.temporaryDocument.devices.push({
              address: j + 1,
              settings: [],
              hardwareDeviceName: `Address ${j + 1}`,
              hardwareType: HardwareType.Undefined,
              id: uuid.v4(),
              name: `Address ${j + 1}`,
              parentDeviceId: slotId,
            });
          }
        }
      }
      break;

    case OpenDocumentActionType.close:
      draft.currentDocument = null;
      draft.currentDeviceId = null;
      break;

    case OpenDocumentActionType.closeAll:
      draft.currentDocument = null;
      draft.currentDeviceId = null;
      draft.documents = [];
      break;

    case OpenDocumentActionType.open:
      return setCurrentDocument(action.payload.document);

    case OpenDocumentActionType.removeDevice:
      if (!draft.currentDocument) return;
      rootParentDeviceId = findRootDevice(action.payload.deviceId);
      if (action.payload.deviceId && draft.currentDocument.temporaryDocument.devices?.length > 0) {
        draft.currentDocument.temporaryDocument.devices = removeDevice(action.payload.deviceId, draft.currentDocument.temporaryDocument.devices);
        draft.currentDocument.changed = true;
        if (draft.currentDocument.temporaryDocument.devices.length === 0) draft.currentDeviceId = null;
        if (draft.currentDeviceId === action.payload.deviceId) draft.currentDeviceId = null;
        if (rootParentDeviceId !== action.payload.deviceId) setParentReadersCount(rootParentDeviceId);
        return;
      }
      if (!draft.currentDocument.temporaryDocument.devices || !draft.currentDeviceId) return;
      draft.currentDocument.temporaryDocument.devices = removeDevice(draft.currentDeviceId, draft.currentDocument.temporaryDocument.devices);
      draft.currentDeviceId = null;
      draft.currentDocument.changed = true;
      if (draft.currentDocument.temporaryDocument.devices.length === 0) draft.currentDeviceId = null;
      if (rootParentDeviceId !== action.payload.deviceId) setParentReadersCount(rootParentDeviceId);
      break;

    case OpenDocumentActionType.save:
      if (!draft.currentDocument) return;
      if (!action.payload.document) return;
      draft.currentDocument.savedDocument = action.payload.document;
      draft.currentDocument.temporaryDocument = action.payload.document;
      draft.currentDocument.changed = false;
      draft.currentDocument.saving = false;
      break;

    case OpenDocumentActionType.saveDevice:
      if (!draft.currentDocument) return;
      if (!draft.currentDeviceId) return;
      draft.currentDocument.savedDocument.devices = draft.currentDocument?.temporaryDocument.devices?.filter(d => d.id !== draft.currentDeviceId) || [];
      currentDevice = draft.currentDocument?.temporaryDocument.devices?.find(d => d.id === draft.currentDeviceId);
      if (!currentDevice) return;
      draft.currentDocument.savedDocument.devices.push(currentDevice);
      break;

    case OpenDocumentActionType.saving:
      if (!draft.currentDocument) return;
      draft.currentDocument.saving = true;
      break;

    case OpenDocumentActionType.setCurrentDocument:
      return setCurrentDocument(action.payload.document);

    case OpenDocumentActionType.setCurrentDeviceId:
      if (!draft.currentDocument) return;
      if (draft.currentDeviceId === action.payload.deviceId) return;
      if (action.payload.deviceId === null) {
        draft.currentDeviceId = null;
        return;
      }
      foundDeviceInCurrentDocument = draft.currentDocument?.temporaryDocument?.devices?.find(d => d.id === action.payload.deviceId) !== undefined;
      if (!foundDeviceInCurrentDocument) return;
      draft.currentDeviceId = action.payload.deviceId;
      break;

    case OpenDocumentActionType.setDevices:
      if (!draft.currentDocument) return;
      if (!draft.currentDocument.temporaryDocument) return;
      if (!action.payload.devices) return;
      draft.currentDocument.temporaryDocument.devices = action.payload.devices
        .map(d => handleDeviceSegments(draft.currentDocument.temporaryDocument, d));
      draft.currentDocument.changed = true;
      break;

    case OpenDocumentActionType.setParentReadersCount:
      if (!action.payload.deviceId) return;
      rootParentDeviceId = findRootDevice(action.payload.deviceId);
      setParentReadersCount(rootParentDeviceId);
      break;

    case OpenDocumentActionType.undo:
      if (!draft.currentDocument) return
      draft.currentDocument.temporaryDocument = draft.currentDocument.savedDocument;
      draft.currentDocument.changed = false;
      foundDeviceInCurrentDocument = draft.currentDocument?.temporaryDocument?.devices?.find(d => d.id === draft.currentDeviceId) !== undefined;
      if (!foundDeviceInCurrentDocument) draft.currentDeviceId = null;
      break;

    case OpenDocumentActionType.undoDevice:
      if (!draft.currentDocument) return;
      if (!draft.currentDeviceId && !action.payload.deviceId) return;
      draft.currentDocument.temporaryDocument.devices = draft.currentDocument?.temporaryDocument.devices
        ?.filter(d => {
          if (action.payload.deviceId !== null && action.payload.deviceId !== undefined) return d.id !== action.payload.deviceId;
          return d.id !== draft.currentDeviceId;
        }) || [];
      savedDevice = draft.currentDocument?.savedDocument.devices
        ?.find(d => {
          if (action.payload.deviceId !== null && action.payload.deviceId !== undefined) return d.id === action.payload.deviceId;
          return d.id === draft.currentDeviceId
      });
      if (!savedDevice) {
        const tempDevice = draft.currentDocument.temporaryDocument.devices?.find(d => {
          if (action.payload.deviceId !== null && action.payload.deviceId !== undefined) return d.id === action.payload.deviceId;
          return d.id === draft.currentDeviceId;
        }) || undefined;
        if (!tempDevice) return;
        savedDevice = {
          ...tempDevice,
          settings: tempDevice.settings?.map(settings => {
            if (!settings) return settings;
            const newSettings: DeviceSettings = {
              ...settings,
              value: '',
            };
            return newSettings;
          }),
        };
      }
      if (!savedDevice) return;
      if (!draft.currentDocument.temporaryDocument.devices) draft.currentDocument.temporaryDocument.devices = [];
      draft.currentDocument.temporaryDocument.devices.push(savedDevice);
      draft.currentDocument.changed = true;
      rootParentDeviceId = findRootDevice(action.payload.deviceId);
      setParentReadersCount(rootParentDeviceId);
      break;

    case OpenDocumentActionType.update:
      if (!draft.currentDocument) return;
      draft.currentDocument.temporaryDocument = action.payload.document;
      draft.currentDocument.changed = true;
      break;

    case OpenDocumentActionType.updateDevice:
      if (!draft.currentDocument) return;
      if (!action.payload.device?.id) return;
      draft.currentDocument.temporaryDocument.devices = draft.currentDocument?.temporaryDocument.devices?.filter(d => d.id !== action.payload.device.id) || [];
      draft.currentDocument.temporaryDocument.devices.push(action.payload.device);
      draft.currentDocument.changed = true;
      break;

    case OpenDocumentActionType.updateDeviceForRulesEvaluation:
      if (!draft.currentDocument) return;
      if (!action.payload.device?.id) return;
      draft.currentDocument.temporaryDocument.devices = draft.currentDocument?.temporaryDocument.devices?.filter(d => d.id !== action.payload.device.id) || [];
      draft.currentDocument.temporaryDocument.devices.push(action.payload.device);
      break;

    default:
      return;
  }
}

export function OpenDocumentsProvider({ children }: { children: ReactNode }) {

  const [openDocumentsContext, openDocumentsContextDispatch] = useImmerReducer(openDocumentsReducer, initialOpenDocumentsContext);

  useEffect(() => {
    if (devTools) {
      devTools.init(openDocumentsContext);
    }
  }, []);

  const openDocumentsContextDispatchWithDevTools = useCallback((action: OpenDocumentsAction) => {
    openDocumentsContextDispatch(action);
    if (devTools) {
      devTools.send(action.type, openDocumentsContext);
    }
  }, [openDocumentsContext]);

  return(
    <OpenDocumentsContext.Provider
      value={
        {
          currentDeviceId: openDocumentsContext.currentDeviceId,
          currentDocument: openDocumentsContext.currentDocument,
          documents: openDocumentsContext.documents,
          dispatch: isDevelopment ? openDocumentsContextDispatchWithDevTools : openDocumentsContextDispatch,
        }
      }
    >
      {children}
    </OpenDocumentsContext.Provider>
  );
}
