import {
  ILanguage,
  Images,
  Languages,
  URLS,
} from '../../constants';
import TopNavigation, { TopNavigationProps } from '@amzn/awsui-components-react/polaris/top-navigation';
import {
  useBundle,
  useLocalizationContext,
} from '@amzn/react-arb-tools';
import { IsItDown } from './IsItDown';
import { Spinner } from '@amzn/awsui-components-react';
import { UserPreferences } from './UserPreferences';
import { debug } from '../../utils';
import { useSessionContext } from './SessionContext';
import { useState } from 'react';

interface ITopNav {
  setShowSelectSite: Function;
}

export default function TopNav(props: ITopNav) {
  debug(`TopNav() props is ${JSON.stringify(props)}`);

  const sessionContext = useSessionContext();

  const [bundle, isBundleLoading] = useBundle('components.common.TopNav');

  const { localizationContext } = useLocalizationContext();

  const stage = 'beta';
  debug(`TopNav() stage is ${stage}`);

  const [ title ] = useState<string>(() => {
    let title = 'SPOT';
    if (stage.toLowerCase() !== 'prod') title += ` - ${stage.toUpperCase()}`;
    return title;
  });

  const [ showUserPreferences, setShowUserPreferences ] = useState<boolean>(false);

  if (isBundleLoading) return <Spinner/>;

  const topMenuItems: TopNavigationProps.Utility[] = [
    {
      type: 'button',
      iconName: 'edit', 
      text: sessionContext.siteCode ? `${sessionContext.siteCode} - ${bundle.getMessage('change-site')}` : bundle.getMessage('change-site'),
      onClick: () => {props.setShowSelectSite(true)}
    },
    {
      type: 'button',
      text: bundle.getMessage('user-preferences'),
      iconName: 'settings',
      ariaLabel: 'User Preferences',
      badge: false,
      disableUtilityCollapse: true,
      onClick: () => {
        setShowUserPreferences(true);
      },
    },
    {
      type: 'button',
      text: 'Guardian Hub',
      ariaLabel: 'Guardian Hub',
      href: URLS.GuardianHub,
      external: true,
      badge: false,
      disableUtilityCollapse: true,
    },
    {
      type: 'menu-dropdown',
      text: bundle.getMessage('help'),
      items: [
        {
          id: 'bug',
          text: bundle.getMessage('report-an-issue'),
          href: URLS.ReportABug,
          external: true,
          externalIconAriaLabel: ' (opens in new tab)'
        },
        {
          id: 'feature',
          text: bundle.getMessage('request-a-feature'),
          href: URLS.FeatureRequest,
          external: true,
          externalIconAriaLabel: ' (opens in new tab)'
        },
        {
          id: 'guide',
          text: bundle.getMessage('user-guide'),
          href: URLS.UserGuide,
          external: true,
          externalIconAriaLabel: ' (opens in new tab)'
        },
      ]
    },
    {
      type: 'button',
      text: `${sessionContext.username}@`,
      iconName: 'user-profile',
      disableTextCollapse: true,
    }
  ];

  const closeUserPreferences = () => {
    setShowUserPreferences(false);
  };

  const getLanguage = (): ILanguage | undefined => {
    const language = Languages.find(l => l.id == localizationContext.getLocale());
    if (!language) return undefined;
    return language;
  };

  return (
    <>
      <IsItDown />
      <TopNavigation
        identity={{
          href: '#',
          title: title,
          logo: {
            src: Images.home,
            alt: bundle.getMessage('home')
          }
        }}
        data-testid='TopNav'
        utilities={topMenuItems}
      />
      {
        showUserPreferences &&
        <UserPreferences
          closeUserPreferencesCallback={closeUserPreferences}
          language={getLanguage()}
        />
      } 
    </>
  );
}
