import {
  Device,
  DeviceSettings,
  HardwareTemplate,
} from 'src/API';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { debug } from 'src/utils';
import { useImmerReducer } from 'use-immer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: {
      connect: () => {
        init: (state: any) => void;
        send: (action: string, state: any) => void;
      };
    };
  }
}

const stage = 'beta';
// @ts-ignore
const isDevelopment = stage === 'test';
const devTools = isDevelopment ? window.__REDUX_DEVTOOLS_EXTENSION__?.connect() : undefined;

export enum OpenTemplateActionType {
  addDevice = 'addDevice',
  close = 'close',
  open = 'open',
  removeDevice = 'removeDevice',
  save = 'save',
  saveDevice = 'saveDevice',
  saving = 'saving',
  setCurrentDeviceId = 'setCurrentDeviceId',
  setCurrentTemplate = 'setCurrentTemplate',
  undo = 'undo',
  undoDevice = 'undoDevice',
  update = 'update',
  updateDevice = 'updateDevice',
}

export interface IOpenTemplate {
  changed: boolean;
  id: string;
  savedTemplate: HardwareTemplate;
  saving: boolean;
  temporaryTemplate: HardwareTemplate;
}

export interface IOpenTemplatesContext {
  currentDeviceId: string | null,
  currentTemplate: IOpenTemplate | null,
  dispatch: Function;
  templates: IOpenTemplate[];
}

const initialOpenTemplatesContext: IOpenTemplatesContext = {
  currentDeviceId: null,
  currentTemplate: null as IOpenTemplate | null,
  dispatch: openTemplatesReducer,
  templates: [],
};

export const OpenTemplatesContext = createContext<IOpenTemplatesContext>(initialOpenTemplatesContext);

export function useOpenTemplatesContext() {
  return useContext(OpenTemplatesContext);
}

type OpenTemplatesAction =
  | { type: OpenTemplateActionType.addDevice; payload: { device: Device } }
  | { type: OpenTemplateActionType.close; payload: { } }
  | { type: OpenTemplateActionType.open; payload: { template: HardwareTemplate } }
  | { type: OpenTemplateActionType.save; payload: { template: HardwareTemplate } }
  | { type: OpenTemplateActionType.saveDevice; payload: { } }
  | { type: OpenTemplateActionType.saving; payload: { } }
  | { type: OpenTemplateActionType.removeDevice; payload: { deviceId?: string } }
  | { type: OpenTemplateActionType.setCurrentDeviceId; payload: { deviceId: string } }
  | { type: OpenTemplateActionType.setCurrentTemplate; payload: { template: HardwareTemplate } }
  | { type: OpenTemplateActionType.undo; payload: { } }
  | { type: OpenTemplateActionType.undoDevice; payload: { deviceId?: string } }
  | { type: OpenTemplateActionType.update; payload: { template: HardwareTemplate } }
  | { type: OpenTemplateActionType.updateDevice; payload: { device: Device } };

function openTemplatesReducer(draft: IOpenTemplatesContext, action: OpenTemplatesAction) {
  debug(`openTemplatesReducer() draft is ${JSON.stringify(draft)} action is ${JSON.stringify(action)}`);
  debug(`openTemplatesReducer() action is ${JSON.stringify(action)}`);
  debug(`openTemplatesReducer() action.type is ${action.type}`);

  const setCurrentTemplate = (template: HardwareTemplate) => {
    if (draft.currentTemplate?.id && draft.currentTemplate?.id !== (template?.id || '')) {
      // add current template to templates
      const currentTemplateIndex = draft.templates.findIndex(d => d.id === draft.currentTemplate!.id);
      if (currentTemplateIndex >= 0) {
        // remove if current template found in templates
        draft.templates.splice(currentTemplateIndex, 1);
      }
      draft.templates.push(draft.currentTemplate);
    }
    if (template === null) {
      draft.currentTemplate = null;
      return;
    }
    const newCurrentTemplate: IOpenTemplate = 
      {
        changed: false,
        id: template.id,
        savedTemplate: template,
        saving: false,
        temporaryTemplate: template,
      };
    draft.currentTemplate = newCurrentTemplate;
    const newCurrentTemplateIndex = draft.templates.findIndex(d => d.id === newCurrentTemplate.id);
    if (newCurrentTemplateIndex >= 0) {
      // remove new current template from templates
      draft.templates.splice(newCurrentTemplateIndex, 1);
    }
  };

  const removeDevice = (deviceId: string, devices: Device[]): Device[] => {
    const device = draft.currentTemplate?.temporaryTemplate.devices?.find(d => d.id === deviceId);
    if (!device) return devices;
    const childDevices = draft.currentTemplate?.temporaryTemplate.devices?.filter(d => d.parentDeviceId === deviceId);
    if (childDevices && childDevices.length > 0) {
      childDevices.forEach(childDevice => {
        removeDevice(childDevice.id, devices);
      });
    }
    const deviceIndex = devices.findIndex(d => d.id === device.id);
    if (deviceIndex >= 0) {
      devices.splice(deviceIndex, 1);
    } 
    return devices;
  }

  let
    currentDevice: Device | undefined,
    foundDeviceInCurrentTemplate: boolean,
    savedDevice: Device | undefined;

  switch (action?.type) {

    case OpenTemplateActionType.addDevice:
      if (!draft.currentTemplate) return;
      if (!action.payload.device) return;
      if (action.payload.device.parentDeviceId) {
        const parentDevice = draft.currentTemplate?.temporaryTemplate.devices?.find(d => d.id === action.payload.device.parentDeviceId);
        if (!parentDevice) throw new Error('parent device not found');
      }
      if (draft.currentTemplate?.temporaryTemplate.devices?.find(d => d.id === action.payload.device.id)) {
        return;
      }
      if (!draft.currentTemplate?.temporaryTemplate.devices) draft.currentTemplate.temporaryTemplate.devices = [];
      draft.currentTemplate.temporaryTemplate.devices.push(action.payload.device);
      draft.currentTemplate.changed = true;
      draft.currentDeviceId = action.payload.device.id;
      break;

    case OpenTemplateActionType.close:
      draft.currentTemplate = null;
      draft.currentDeviceId = null;
      break;

    case OpenTemplateActionType.open:
      setCurrentTemplate(action.payload.template);
      break;

    case OpenTemplateActionType.removeDevice:
      if (!draft.currentTemplate) return;
      if (action.payload.deviceId
        && draft.currentTemplate.temporaryTemplate.devices?.length
        && draft.currentTemplate.temporaryTemplate.devices?.length > 0)
      {
        draft.currentTemplate.temporaryTemplate.devices = removeDevice(action.payload.deviceId, draft.currentTemplate.temporaryTemplate.devices);
        draft.currentTemplate.changed = true;
        if (draft.currentTemplate.temporaryTemplate.devices.length === 0) draft.currentDeviceId = null;
        if (draft.currentDeviceId === action.payload.deviceId) draft.currentDeviceId = null;
        return;
      }
      if (!draft.currentTemplate.temporaryTemplate.devices || !draft.currentDeviceId) return;
      draft.currentTemplate.temporaryTemplate.devices = removeDevice(draft.currentDeviceId, draft.currentTemplate.temporaryTemplate.devices);
      draft.currentDeviceId = null;
      draft.currentTemplate.changed = true;
      if (draft.currentTemplate.temporaryTemplate.devices.length === 0) draft.currentDeviceId = null;
      break;

    case OpenTemplateActionType.save:
      if (!draft.currentTemplate) return;
      if (!action.payload.template) return;
      draft.currentTemplate.savedTemplate = action.payload.template;
      draft.currentTemplate.savedTemplate = action.payload.template;
      draft.currentTemplate.changed = false;
      draft.currentTemplate.saving = false;
      break;

    case OpenTemplateActionType.saveDevice:
      if (!draft.currentTemplate) return;
      if (!draft.currentDeviceId) return;
      draft.currentTemplate.savedTemplate.devices = draft.currentTemplate?.temporaryTemplate.devices?.filter(d => d.id !== draft.currentDeviceId) || [];
      currentDevice = draft.currentTemplate?.temporaryTemplate.devices?.find(d => d.id === draft.currentDeviceId);
      if (!currentDevice) return;
      draft.currentTemplate.savedTemplate.devices.push(currentDevice);
      break;

    case OpenTemplateActionType.saving:
      if (!draft.currentTemplate) return draft;
      draft.currentTemplate.saving = true;
      break;

    case OpenTemplateActionType.setCurrentTemplate:
      setCurrentTemplate(action.payload.template);
      break;

    case OpenTemplateActionType.setCurrentDeviceId:
      if (!draft.currentTemplate) return;
      if (draft.currentDeviceId === action.payload.deviceId) return;
      if (action.payload.deviceId === null) {
        draft.currentDeviceId = null;
        return;
      }
      foundDeviceInCurrentTemplate = draft.currentTemplate?.temporaryTemplate?.devices?.find(d => d.id === action.payload.deviceId) !== undefined;
      if (!foundDeviceInCurrentTemplate) return;
      draft.currentDeviceId = action.payload.deviceId;
      break;

    case OpenTemplateActionType.undo:
      if (!draft.currentTemplate) return;
      draft.currentTemplate.temporaryTemplate = draft.currentTemplate.savedTemplate;
      draft.currentTemplate.changed = false;
      draft.currentDeviceId = null;
      break;

    case OpenTemplateActionType.undoDevice:
      if (!draft.currentTemplate) return;
      if (!draft.currentDeviceId && !action.payload.deviceId) return;
      draft.currentTemplate.temporaryTemplate.devices = draft.currentTemplate?.temporaryTemplate.devices
        ?.filter(d => {
          if (action.payload.deviceId !== null && action.payload.deviceId !== undefined) return d.id !== action.payload.deviceId;
          return d.id !== draft.currentDeviceId;
        }) || [];
      savedDevice = draft.currentTemplate?.savedTemplate.devices
        ?.find(d => {
          if (action.payload.deviceId !== null && action.payload.deviceId !== undefined) return d.id === action.payload.deviceId;
          return d.id === draft.currentDeviceId
      });
      if (!savedDevice) {
        const tempDevice = draft.currentTemplate.temporaryTemplate.devices?.find(d => {
          if (action.payload.deviceId !== null && action.payload.deviceId !== undefined) return d.id === action.payload.deviceId;
          return d.id === draft.currentDeviceId;
        }) || undefined;
        if (!tempDevice) return;
        savedDevice = {
          ...tempDevice,
          settings: tempDevice.settings?.map(settings => {
            if (!settings) return settings;
            const newSettings: DeviceSettings = {
              ...settings,
              value: '',
            };
            return newSettings;
          }),
        };
      }
      if (!savedDevice) return;
      if (!draft.currentTemplate.temporaryTemplate.devices) draft.currentTemplate.temporaryTemplate.devices = [];
      draft.currentTemplate.temporaryTemplate.devices.push(savedDevice);
      draft.currentTemplate.changed = true;
      break;

    case OpenTemplateActionType.update:
      if (!draft.currentTemplate) return;
      draft.currentTemplate.temporaryTemplate = action.payload.template;
      draft.currentTemplate.changed = true;
      break;

    case OpenTemplateActionType.updateDevice:
      if (!draft.currentTemplate) return;
      draft.currentTemplate.temporaryTemplate.devices = draft.currentTemplate?.temporaryTemplate.devices?.filter(d => d.id !== action.payload.device.id) || [];
      draft.currentTemplate.temporaryTemplate.devices.push(action.payload.device);
      draft.currentTemplate.changed = true;
      break;

    default:
      return;
  }
}

export function OpenTemplatesProvider({ children }: { children: ReactNode }) {

  const [openTemplatesContext, openTemplatesContextDispatch] = useImmerReducer(openTemplatesReducer, initialOpenTemplatesContext);

  useEffect(() => {
    if (devTools) {
      devTools.init(openTemplatesContext);
    }
  }, []);

  const openTemplatesContextDispatchWithDevTools = useCallback((action: OpenTemplatesAction) => {
    openTemplatesContextDispatch(action);
    if (devTools) {
      devTools.send(action.type, openTemplatesContext);
    }
  }, [openTemplatesContext]);

  return(
    <OpenTemplatesContext.Provider
      value={
        {
          currentDeviceId: openTemplatesContext.currentDeviceId,
          currentTemplate: openTemplatesContext.currentTemplate,
          dispatch: isDevelopment ? openTemplatesContextDispatchWithDevTools : openTemplatesContextDispatch,
          templates: openTemplatesContext.templates,
        }
      }
    >
      {children}
    </OpenTemplatesContext.Provider>
  );
}
